@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");

body {
  font-family: "Ubuntu", sans-serif;
  background-image: radial-gradient(
    farthest-corner at 40px 40px,
    #f35 0%,
    #43e 100%
  );
}


.flip-card-outer {
  height: 70vh;

  &.focus-trigger:focus {
    outline: 5px solid greenyellow;
    outline-offset: 5px;
  }

  .flip-card-inner {
    transform-style: preserve-3d;
    transition: 0.5s linear 0.1s;
    position: relative;
    width: inherit;
    height: inherit;

    &.hover-trigger:hover {
      transform: rotateY(180deg);
    }

    &.showBack {
      transform: rotateY(180deg);
    }

    .card {
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 50px;
      border-radius: 1rem;

      &.front {
        transform: rotateY(0);
        background-color: #CCCCFA;
        color: #222222;
      }

      &.back {
        transform: rotateY(180deg);
        background-color: #7879F1;
        color: #2d2d2d;
      }

      .click_text {
        top: 0;
        left: 0;
        font-size: 16;
      }
    }
  }
}
