@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,300&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #A5A6F6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  vertical-align: middle;
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{font-family:"Ubuntu",sans-serif;background-image:radial-gradient(farthest-corner at 40px 40px, #f35 0%, #43e 100%)}.flip-card-outer{height:70vh}.flip-card-outer.focus-trigger:focus{outline:5px solid #adff2f;outline-offset:5px}.flip-card-outer .flip-card-inner{transform-style:preserve-3d;transition:.5s linear .1s;position:relative;width:inherit;height:inherit}.flip-card-outer .flip-card-inner.hover-trigger:hover{transform:rotateY(180deg)}.flip-card-outer .flip-card-inner.showBack{transform:rotateY(180deg)}.flip-card-outer .flip-card-inner .card{-webkit-backface-visibility:hidden;backface-visibility:hidden;position:absolute;top:0;left:0;width:100%;height:100%;padding:50px;border-radius:1rem}.flip-card-outer .flip-card-inner .card.front{transform:rotateY(0);background-color:#ccccfa;color:#222}.flip-card-outer .flip-card-inner .card.back{transform:rotateY(180deg);background-color:#7879f1;color:#2d2d2d}.flip-card-outer .flip-card-inner .card .click_text{top:0;left:0;font-size:16}
.logo{height:8vh}


.sc-gtsrHT.bzPgCx.rsc-cs{display:none}





.App-header{background-color:#a5a6f6;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App{text-align:center}
